import { number } from "yup";

export const SalesConditions = () => {
  const tjenesteyterensAnsvar = [
    {
      number: "9.1.1",
      text: "Tjenesteyteren har ansvar for å utføre oppdraget med rasjonalitet og god faglig standard, og innenfor de avtalte frister. Tjenesteyteren er pliktig å gjøre Kunden kjent med de formelle, materielle og rutinemessige forutsetninger for oppdragets gjennomførelse",
    },
    {
      number: "9.1.2",
      text: "Tjenesteyteren har fullt arbeidsgiveransvar for sitt servicepersonell. Ved bruk av underleverandør skal Kunden kun forholde seg til Tjenesteyteren i alle kontraktsspørsmål.",
    },
    {
      number: "9.1.3",
      text: " Tjenesteyteren er ansvarlig for forsinkelser og/eller skader som følge av svikt i utstyr som er levert eller som har vært gjenstand for service dersom skaden skyldes at Tjenesteyteren eller personell i hans tjeneste har utvist uaktsomhet eller forsett.",
    },
    {
      number: "9.1.4",
      text: "Kundens interesser skal ivaretas med tilbørlig omsorg. Tjenesteyteren er ansvarlig for å gi Kunden tilstrekkelig opplæring/veiledning til bruk og vedlikehold av anlegg/utstyr mellom serviceintervallene.",
    },
    {
      number: "9.1.5",
      text: "Tjenesteyter skal påvise feil, mangler eller slitasje som krever utbedring, gi tilbud, og etter å ha mottatt bestilling, så snart som mulig utføre utbedringene for å hindre unødvendig driftsstans eller skade.",
    },
    {
      number: "9.1.6",
      text: "Ved eventuelle endringer i utstyrets funksjon, bruk etc., skal Tjenesteyter informere Kunden om dette, og om nødvendig gi opplæring i bruk av de endrede funksjonene.",
    },
  ];

  const kundensAnsvar = [
    {
      number: "9.2.1",
      text: "Kunden har ansvar for, innenfor rimelighetens grenser, å legge forholdene til rette slik at oppdraget kan gjennomføres effektivt og i samsvar med avtalen (medvirkningsplikt).",
    },
    {
      number: "9.2.2",
      text: "Kunden plikter å melde fra om feil/skade på utsyr/anlegg dersom det er grunn til å tro at feilen/skaden skyldes forhold nevnt under punkt 9.1 3. Varselet skal skje skriftlig og uten opphold.",
    },
    {
      number: "9.2.3",
      text: "Eventuelle reklamasjoner skal Kunden melde fra om i rimelig tid etter at servicen/reparasjonen det reklameres på er utført/ikke utført.",
    },
    {
      number: "9.2.4",
      text: "Kunden skal gi servicepersonell rask adgang til anlegget og om nødvendig være behjelpelig med assistanse.",
    },
  ];
  return (
    <div className="mt-20">
      <div class="max-w-7xl mx-auto p-8 bg-gray-50 shadow-lg rounded-lg">
        <h1 class="text-4xl font-bold text-center text-blue-600 mb-10">
          Betingelser for bruk av tjenester, salg og serviceavtaler
        </h1>

        <p class="text-md italic text-center text-blue-600 mb-10">
          Sist oppdatert 23.02.2021 - Rev. 2.0
        </p>

        <p class="mb-8">
          De alminnelige betingelsene nedenfor gjelder kjøp eller deler av kjøp
          som er å anse som forbrukerkjøp i samsvar med forbrukerkjøpsloven § 1
          og/eller oppdrag til forbruker etter håndverkertjenesteloven § 1
          og/eller delentreprise til kunden etter bustadoppføringslovens § 1(1)
          a. Disse alminnelige betingelser omfatter alle ytelser som leveres fra
          leverandøren, herunder ventilasjonsarbeid, levering av utstyr,
          materiell og installering av slikt utstyr samt service og vedlikehold
          av tekniske anlegg.
        </p>

        <div class="space-y-12">
          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              1. Definisjoner
            </h2>
            <p class="mt-4">
              <strong>Leverandør:</strong> AIRON AS, Orgnr.: 922 945 098 MVA
            </p>
            <p>
              Med «kunde/kunden» menes den person eller instans som bestiller en
              tjeneste eller vare/produkt fra AIRON AS.
            </p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              2. Avtaleforholdet
            </h2>
            <p class="mt-4">
              Leverandørens alminnelige leverings- og betalingsbetingelser er en
              del av avtaleforholdet mellom partene, og vil gjelde fullt ut med
              mindre avvik er skriftlig avtalt eller annet følger av
              ufravikelige regler etter forbrukerkjøpsloven,
              håndverkertjenesteloven eller bustadoppføringsloven.
            </p>

            <p class="mt-4">
              Avtale anses inngått ved undertegning av eventuell
              avtale/kontrakt, kundes aksept/elektronisk signering av tilbud,
              leverandørens skriftlige ordrebekreftelse eller ved påbegynt
              levering/oppdrag.
            </p>

            <p class="py-4">Bestemmelser som dagmulkt krever nærmere avtale.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              3. Bestilling av varer
            </h2>
            <ol class="list-decimal list-inside mt-4 space-y-2">
              <li>
                <strong>Ordrens gyldighet:</strong>
                <p class="ml-4 mt-4">
                  En ordre er gyldig når ordrebekreftelse er mottatt.
                  Ordrebekreftelse gis pr. e-post.
                </p>
                <p class="ml-4 mt-4">
                  Ved registrering av en ordre må kunden godta AIRON AS sine
                  salgsbetingelser. En ordre/bestilling er gyldig inntil varen
                  er avbestilt eller AIRON AS skriftlig/via e-post kansellerer
                  ordren.
                </p>

                <p class="ml-4 py-4">
                  AIRON AS tar forbehold om at enkelte varer kan være utsolgt
                  selv om annet kommer frem i nettbutikken.
                </p>
              </li>

              <li>
                <strong>Mangler i leveranse:</strong>
                <p class="ml-4 py-4">
                  Eventuelle mangler ved en leveranse må rapporteres til AIRON
                  AS i rimelig tid etter at feilen oppdages.
                </p>
              </li>

              <li>
                <strong>Befraktning:</strong>
                <p class="ml-4 py-4">
                  Alle varer til privatpersoner og firmaer sendes normalt med
                  Posten eller Schenker iht. avtalt fraktmåte. AIRON AS velger
                  billigste befraktningsmåte, men står fritt til å velge annen
                  befrakter.
                </p>
              </li>

              <li>
                <strong>Returrett:</strong>
                <p class="ml-4 mt-4">
                  Gjelder for privatpersoner: AIRON AS følger innen Norge
                  prinsippene i angrerett-loven for retur av varer, med rett til
                  pengene tilbake. Varer må returneres i vesentlig samme stand
                  som de ble mottatt. Ved korrekt gjennomført retur (iht.
                  angrefristloven) tilbakebetales varens fulle pris og utlagte
                  fraktkostnader til kjøper.
                </p>

                <p class="ml-4 py-4">
                  Kjøper har 14 dagers returrett på alle varer i nettbutikken.
                </p>
              </li>

              <li>
                <strong>Uavhentede leveranser:</strong>
                <p class="ml-4 py-4">
                  Ved uavhentet leveranser/pakker vil kunden ilegges sum av
                  følgende: 500 kroner i gebyr for uavhentet vare, dette er
                  nødvendig for å dekke våre kostnader i forbindelse med slike
                  brudd på avtale.
                </p>
              </li>

              <li>
                <strong>Dokumentasjon ved forsendelser:</strong>
                <p class="ml-4 py-4">
                  Alle forsendelser til AIRON AS vedrørende returer,
                  reklamasjoner og lignende må vedlegges et følgeskriv som
                  refererer til opprinnelig fakturanummer, kjøpsdato, samt
                  informasjon om hva forsendelsen gjelder. Forsendelsen til
                  AIRON AS skal skje kostnadsfritt for AIRON AS. Pakker som er
                  sendt i oppkrav eller «mottaker betaler frakt» vil ikke bli
                  hentet ut.
                </p>
              </li>

              <li>
                <strong>Leveringsinformasjon:</strong>
                <p class="ml-4 py-4">
                  Ved bestilling av varer får du en bekreftelse per e-post eller
                  SMS på at tjenestene/ varene er bestilt og registrert hos oss.
                </p>
              </li>

              <li>
                <strong>Mangler/feil ved produkter:</strong>
                <p class="ml-4 py-4">
                  Oppdages det eventuelle mangler eller mulige feil ved et
                  produkt, skal kunden innen rimelig tid etter at mangelen
                  oppdages, rapportere mangelen til AIRON AS.
                </p>
              </li>
            </ol>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              4. Salgspant
            </h2>
            <p class="mt-4">
              AIRON AS har salgspant i – og eiendomsretten til – alle varer og
              demonterbare komponenter inntil faktura med renter og omkostninger
              er fullt betalt.
            </p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              5. Omfang og gjennomføring av tjenester
            </h2>
            <p class="mt-4">
              Leverandøren skal igangsette monteringsarbeid til avtalt tid, så
              fremt leverandøren får tilgang til monteringsstedet til avtalt tid
              eller i avtalte periode, og kunden har lagt til rette for at
              monteringsarbeidet kan igangsettes og gjennomføres uten risiko for
              at arbeidet kan bli forsinket.
            </p>

            <p class="mt-4">
              AIRON AS møter opp til avtalt tid, som er avtalt muntlig, via
              e-post eller via vår SMS-tjeneste.
            </p>

            <p class="mt-4">
              Kunde skal varsle AIRON hvis tidspunkt for oppdraget må utsettes,
              varslingen skal skje i god tid (minst 2 dager) før oppmøte.
            </p>

            <p class="mt-4">
              AIRON kan gi kunde kort varsel om att oppdrag må utsettes pga.
              sykdom eller andre lignende grunner.
            </p>

            <p class="mt-4">
              Om AIRON møter til oppdragsstedet uten av kunde eller
              kontaktperson er tilstede uten at det er gitt tilgang til
              oppdragsstedet/ anlegget, så faktureres avtalt oppmøte iht. punkt
              6.
            </p>

            <p class="mt-4">
              Dersom det er avtalt fast pris kan leverandøren fakturere på
              grunnlag av gjeldende timepriser for medgått mertid som følge av
              at arbeidsstedet ikke er tilgjengelig, ikke er ryddet og for
              venting på andre fag.
            </p>
            <ol class="list-decimal list-inside space-y-2 mt-4">
              <li>
                <strong>Beskrivelse av service:</strong>
                <p class="ml-4 mt-4">
                  Serviceavrbeidet skal utføres i normal arbeidstid (07-17:00),
                  med mindre annet er forhåndsgodkjent av kunde. Under
                  servicebesøket vil servicepersonellet får tilgang til alle
                  lokaliter hvor utstyret/ anlegget er installert.
                </p>

                <p class="ml-4 py-4">
                  Kontaktperson i Virksomhetsområde Eiendom skal varsles når
                  servicebesøket påbegynnes, senest en uke før service, om ikke
                  annet avtales og godkjent av kunde
                </p>
              </li>

              <li>
                <strong>Servicepersonell :</strong>
                <p class="ml-4 py-4">
                  Servicen skal utføres av kvalifisert servicepersonell. Med
                  kvalifisert servicepersonell menes at servicepersonellet skal
                  ha den tilstrekkelige faglige og praktiske kompetanse som
                  kreves for å gjennomføre oppdraget i henhold til normale
                  kvalitetskrav og i henhold til gjeldende regelverk.
                </p>
              </li>

              <li>
                <strong>Servicerapporter:</strong>
                <p class="ml-4 py-4">
                  Etter hvert serviceoppdrag skal Kunden motta en servicerapport
                  over utført arbeid med merkander om eventuelle feil og mangler
                  som ble avdekket og eventuelt utbedret/ reparert.
                  Serivcerapporter oversendes kun elektronisk til Kunden via
                  e-post. Rapport sendes ikke automatisk om e-postadresse ikke
                  er oppgitt av kunden.
                </p>
              </li>

              <li>
                <strong>Reparasjoner og utbedringer:</strong>
                <p class="ml-4 py-4">
                  Reparasjonsarbeider utover periodisk service kan utføres etter
                  avtale. Reservedeler faktureres i henhold til avtalte priser.
                </p>
              </li>

              <li>
                <strong>Kundens forsinkelse :</strong>
                <p class="ml-4 mt-4">
                  Forsinkelser som skyldes kundens forhold medfører tilsvarende
                  utsettelse av avtalt levering eller overtagelse. Dersom
                  monteringsarbeidet forsinkes som følge av at kunden ikke har
                  sørget for rettidig adgang til monteringsstedet, eller ikke
                  har lagt forholdene til rette for igangsetting og
                  gjennomføring uten at arbeidet risikerer å bli forsinket, kan
                  AIRON utsette arbeidet til nærmeste tidspunkt eller periode
                  hvor AIRON har anledning til å gjennomføre arbeidet.
                </p>

                <p class="ml-4 py-4">
                  Ved vesentlig forsinkelse fra kundens side, og kunden etter
                  avtalen først skal betale etter at tjenesten er ferdig utført,
                  kan leverandøren del-fakturere kunden for hittil påløpte
                  arbeider, eller på det tidspunktet hvor arbeidet må antas å ha
                  blitt ferdig gjennomført uten forsinkelsen. AIRON kan i
                  tillegg gjøre gjeldende de rettigheter og krav som tilkommer
                  AIRON etter forbrukerkjøpsloven, håndverkertjenesteloven eller
                  bustadoppføringsloven.
                </p>
              </li>
            </ol>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              6. Pris & betalingsbetingelser
            </h2>
            <ol class="list-decimal list-inside space-y-2 mt-4">
              <li>
                <strong>Generelt:</strong>
                <div className="ml-5">
                  <p class="mt-4">
                    Alle priser er normalt oppgitt i norske kroner eksklusive
                    frakt, hvis ikke annet er oppgitt.
                  </p>

                  <p class="mt-4">
                    Priser på produkter gjelder i henholdt til våre veillende
                    priser med avtalte rabatter eller påslag. Våre veilledende
                    priser reguleres etter våre leverandørers prisregulering og/
                    eller årlig indeks.
                  </p>
                  <p class="mt-4">
                    Prisene kan endres uten videre varsel til kjøper. Årsaken
                    kan være endrede leverandørpriser, endrede valutakurser,
                    indeksregulering og lignende. Det er kundens ansvar å holde
                    seg oppdatert om gjeldende priser.
                  </p>

                  <p class="mt-4">
                    AIRON AS kan imidlertid ikke endre prisen på en vare kjøper
                    har fått bekreftet pris på. Det er alltid den bekreftede
                    prisen på ordretidspunkt som gjelder. AIRON AS forplikter
                    seg til å levere varen til avtalt pris.
                  </p>

                  <p class="mt-4">
                    Tjenester og arbeidskraft/ timesatser reguleres hvert år i
                    henhold til Statistisk sentralbyrås lønnsindeks for bygg og
                    anlegg.
                  </p>

                  <p class="py-4">Prisregulering skjer minst én gang per år.</p>
                </div>
              </li>
              <li>
                <strong>Fakturering:</strong>
                <div className="ml-5">
                  <p class="mt-4">
                    I kundeforhold faktureres oppdrag/tjenester med 14 dagers
                    betalingsforfall fra fakturadato. Ved oppdrag utover 1 dags
                    varighet kan AIRON AS a-konto fakturere kunden hver 7. dag
                    for leverte ytelser, herunder da også her med 14 dagers
                    betalingsforfall.
                  </p>

                  <p class="mt-4">
                    Alle fakturaer stilles til oppgitt eller avtalt navn på
                    kunde/ bedrift med adresse som frekommer i serviceavtale,
                    ordrebekreftelse, tilbud eller kontrakt.{" "}
                  </p>
                  <p class="mt-4">
                    Ved forsinket betaling kan leverandøren kreve rente etter
                    lov om renter ved forsinket betaling (lov av 17. desember
                    1976 nr. 100), når kravet ikke innfris ved forfall. Renten
                    løper fra forfallsdag. Påkrav kan sendes ved bruk av
                    elektronisk kommunikasjon.
                  </p>
                  <p class="mt-4">
                    Faktura oversendes til kundens oppgitte e-post adresse eller
                    via EHF. Ønskes papirfaktura må kunden varsle dette til
                    post@airon.no, senest ved oppstart av oppdraget. Det
                    tilkommer et rimelig fakturagebyr ved utstedelse og
                    oversendelse av papirfaktura.
                  </p>
                  <p class="py-4">
                    Leverandøren kan stanse alle arbeider ved manglende betaling
                    av faktura.
                  </p>
                </div>
              </li>
              <li className="mt-4">
                <strong>Prisdefinisjon:</strong>

                <div className="ml-5">
                  <p class="mt-4">
                    Dersom ikke annet er avtalt, er prisen i norske kroner,
                    fast, og eksklusive merverdiavgift, reise- og diettutgifter,
                    kjøre- og bildegodtgjørelse bompenger og parkeringutgifter,
                    administrative eller personlige tillegg og/ eller gebyrer.
                    Utført arbeid faktureres for hver påbegynte time i tillegg
                    tli materiale og evtnuelle utgifter, om ikke annet er
                    avtalt.
                  </p>
                </div>
              </li>
            </ol>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              7. Bruk av underleverandør
            </h2>
            <p class="mt-4">
              Tjenesteyter kan kun benytte seg av underleverandører for å
              oppfylle sine kontraktmessige forpliktelser dersom dette først er
              godkjent av Kunden. I slike tilfeller har Tjenesteyter det totale
              ansvar for tjenester som leveres av underleverandører under denne
              avtalen. Det vil si at eventuelle feil, mangler, forsinkelser o.l.
              som har sin årsak i underleverandør, faller inn under
              Tjenesteyterens ansvar. Tjenesteyter er ansvarlig for at denne
              avtalens betingelser i nødvendig grad blir videreført til
              underleverandører for å kunne ivareta intensjonen med denne
              avtalens bestemmelser.
            </p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              8. Tjenesteyterens ansvarsforsikring og erstatningsansvar
            </h2>
            <p class="mt-4">
              Tjenesteyteren skal ha ansvarsforsikring på vanlige vilkår.
              Forsikringen skal dekke erstatningskrav for skade Tjenesteyteren
              kan påføre Kunden eller tredjeperson og ting i forbindelse med
              utførselen av arbeid etter denne avtalen. Forsikringssummen skal
              ikke være mindre enn 150 G.
            </p>
            <p class="mt-4">
              Kunden kan bare kreve erstatning fra Tjenesteyter for direkte tap
              Kunden blir påført som følge av uaktsom eller forsettlig handling
              eller unnlatelse fra Tjenesteyter ved utførelse av tjenesten.
            </p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              9. Partenes rettigheter og plikter
            </h2>

            <h3 class="ml-4 text-xl font-semibold text-gray-900 mt-4">
              9.1. Tjenesteyterens ansvar
            </h3>

            <ul class="ml-6 list-inside mt-2 space-y-2">
              {tjenesteyterensAnsvar.map((ansvar) => (
                <li>
                  <strong className="mr-2">{ansvar.number}</strong>{" "}
                  {ansvar.text}
                </li>
              ))}
            </ul>

            <h3 class="ml-4 text-xl font-semibold text-gray-900 mt-4">
              9.2. Kundens ansvar
            </h3>

            <ul class="ml-6 list-inside mt-2 space-y-2">
              {kundensAnsvar.map((ansvar) => (
                <li>
                  <strong className="mr-2">{ansvar.number}</strong>{" "}
                  {ansvar.text}
                </li>
              ))}
            </ul>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              10. Reklamasjonsrett
            </h2>
            <p class="mt-4">Reklamasjonsrett etter kjøpslovens bestemmelser.</p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              11. Force Majeure
            </h2>

            <p class="mt-4">
              Følgende omstendigheter skal betraktes som force majeure, dersom
              de inntrer etter avtalens inngåelse og hindrer dens oppfyllelse:
              krig, opprør eller indre uroligheter, beslutning av offentlig
              myndighet, streik, naturkatastrofe, avbrudd i den offentlige
              kraftforsyning eller i den alminnelige samferdsel, betydningsfull
              arbeidskonflikt eller brann eller annen omstendighet av liknende
              karakter og inngripende betydning.
            </p>
            <p class="mt-4">
              AIRON har rett til å kreve at den leveringstid som er fastsatt i
              kontrakten blir forlenget tilsvarende det tidsrom som arbeidet
              måtte bli forsinket ved force majeure så vel i egen bedrift som
              hos underleverandører.
            </p>
            <p class="mt-4">
              AIRON skal straks slik forsinkelse som nevnt ovenfor inntrer eller
              så snart han blir klar over at forsinkelse vil kunne inntreffe, gi
              skriftlig melding om dette med de nødvendige opplysninger.
            </p>
          </section>

          <section>
            <h2 class="text-2xl font-semibold text-gray-900 border-b pb-2">
              12. Tvister
            </h2>
            <p class="mt-4">
              Tvister som måtte oppstå i tilfelle uenighet om bestemmelser eller
              betingelser hører inn under de ordinære domstoler, med Sarpsborg
              Byrett som verneting. Alle rettsspørsmål som måtte oppstå skal
              bedømmes i henhold til norske lover.
            </p>
          </section>
        </div>

        <div class="mt-12 text-center text-sm text-gray-500">
          <p>
            Airon AS | Trøskenveien 36, 1708 Sarpsborg, Norge | Org.nr.: NO 922
            945 098
          </p>
          <p>
            Telefon: 902 405 02 | E-post:{" "}
            <a
              href="mailto:post@airon.no"
              class="text-blue-600 hover:underline"
            >
              post@airon.no
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
